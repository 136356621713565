<template>
    <section ref="stickyInfobar" class="sticky-infobar">
        <div class="sticky-infobar__container">
            <div class="sticky-infobar__infos">
                <img :src="logoUrl" :alt="logoAlt" class="sticky-infobar__logo">
                <span class="sticky-infobar__title">{{ title }}</span>
            </div>
            <div class="sticky-infobar__links">
                <a
                    v-if="githubLink"
                    :href="githubLink"
                    class="sticky-infobar__documentation"
                    target="_blank"
                >Documentation</a>
                <a
                    v-if="storeLink"
                    :href="storeLink"
                    class="sticky-infobar__plugin-store btn-square"
                    target="_blank"
                >Plugin Store</a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'StickyInfobarComponent',
        props: {
            watch: { type: String, required: true },
            title: { type: String, required: true },
            logoUrl: { type: String, required: true },
            logoAlt: { type: String, required: true },
            githubLink: { type: String, default: null },
            storeLink: { type: String, default: null }
        },
        data () {
            return {
                infobarOffsetTop: null,
                isTopBarSticky: false
            }
        },
        mounted: function () {
            this.elWatched = document.querySelector(this.watch)
            this.addEventListeners()
            this.onScroll()
        },
        methods: {
            addEventListeners () {
                window.addEventListener('scroll', this.onScroll.bind(this))
            },

            onScroll () {
                const infobarBoundingBottom = this.elWatched.getBoundingClientRect().bottom
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop
                const infobarOffsetTop = infobarBoundingBottom + scrollTop

                if (!this.isTopBarSticky && scrollTop > infobarOffsetTop) {
                    this.isTopBarSticky = true
                    this.$refs.stickyInfobar.classList.add('sticky-infobar--sticky')
                }

                if (this.isTopBarSticky && scrollTop < infobarOffsetTop) {
                    this.isTopBarSticky = false
                    this.$refs.stickyInfobar.classList.remove('sticky-infobar--sticky')
                }
            }
        }
    }
</script>
