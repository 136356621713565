// Import des styles principaux.

import '@/assets/styles/main.scss';


// Import des dépendances.
// Comment/uncomment au besoin.

/* eslint-disable */
// Vue.js
import Vue from 'vue';

// Lodash
import _ from 'lodash';

// Axios
import axios from 'axios';

// GSAP
// https://greensock.com/docs/NPMUsage
//import TweenMax from "gsap/TweenMax";
import TweenMax from "gsap/TweenLite";

// Imagesloaded
// https://imagesloaded.desandro.com/#webpack
import imageLoaded from 'imagesloaded';

/* eslint-enable */

// Particles JS

import VueParticles from 'vue-particles'

Vue.use(VueParticles);

// Import des composants Vue.js
import ParticlesComponent from '@/app/components/ParticlesComponent';
import StickyTopbarComponent from '@/app/components/StickyTopbarComponent';
import SmoothScrollComponent from '@/app/components/SmoothScrollComponent';
import NavbarComponent from '@/app/components/NavbarComponent';
import HomeNavComponent from '@/app/components/HomeNavComponent';

// Polyfills

import 'picturefill'


// injecte le public path au runtime car pas connu au build. __webpack_public_path__

/* eslint-disable */
if (window.runtime_webpack_public_path) {
    __webpack_public_path__ =  window.runtime_webpack_public_path;
}
/* eslint-enable */


// Instanciate the vue

new Vue({
    el: '#app',
    components: {
        ParticlesComponent,
        StickyTopbarComponent,
        SmoothScrollComponent,
        NavbarComponent,
        HomeNavComponent
    },
    created() {
        console.log('[Vue] App created');
    }
});


/*console.log(
    _.join(['Another', 'module', 'loaded!'], ' ')
);*/
