<template>
    <a ref="link" :class="elClass" href="#"/>
</template>

<script>
    import { TweenLite, ScrollToPlugin } from 'gsap/all'
    // eslint-disable-next-line
const plugins = [ ScrollToPlugin ]; // eslint-disable-line no-unused-vars

    export default {
        name: 'SmoothScrollComponent',
        props: {
            destination: { type: String, required: true },
            elClass: { type: String, default: '' },
            duration: { type: Number, default: 300 }
        },
        data () {
            return {

            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init () {
                this.addEventListeners()
            },

            addEventListeners () {
                this.$refs.link.addEventListener('click', this.scroll.bind(this, this.destination, this.duration))
            },

            scroll (destination, duration, e) {
                e.preventDefault()
                TweenLite.to(window, 2, { scrollTo: { y: this.destination, autoKill: true }, duration: duration, ease: Power4.easeOut }) // eslint-disable-line no-undef
            }
        }
    }
</script>
