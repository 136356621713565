<template>
    <div class="particles-component">
        <vue-particles v-if="enableParticles" :particles-data="customOptions">
            <!-- -->
        </vue-particles>
    </div>
</template>

<script>
    export default {
        name: 'ParticlesComponent',
        props: {
            color: { type: String, default: '#dedede' },
            particleOpacity: { type: Number, default: 0.4 },
            particlesNumber: { type: Number, default: 80 },
            shapeType: { type: String, default: 'circle' },
            particleSize: { type: Number, default: 4 },
            linesColor: { type: String, default: '#dedede' },
            linesWidth: { type: Number, default: 1 },
            lineLinked: { type: Boolean, default: true },
            lineOpacity: { type: Number, default: 0.2 },
            linesDistance: { type: Number, default: 150 },
            moveSpeed: { type: Number, default: 3 },
            hoverEffect: { type: Boolean, default: true },
            hoverMode: { type: String, default: 'repulse' },
            clickEffect: { type: Boolean, default: true },
            clickMode: { type: String, default: 'push' }
        },
        data: function () {
            return {
                enableParticles: false,
                customOptions: {
                    particles: {
                        number: {
                            value: this.particlesNumber,
                            density: {
                                enable: true,
                                value_area: 631.3181133058181
                            }
                        },
                        color: {
                            value: this.color
                        },
                        shape: {
                            // circle, edge, triangle, polygon, star, image
                            type: this.shapeType,
                            stroke: {
                                width: 0,
                                color: '#192231'
                            },
                            polygon: {
                                nb_sides: 5
                            }
                        },
                        opacity: {
                            value: this.particleOpacity,
                            random: false,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false
                            }
                        },
                        size: {
                            value: this.particleSize,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 40,
                                size_min: 0.1,
                                sync: false
                            }
                        },
                        line_linked: {
                            enable: this.lineLinked,
                            distance: this.linesDistance,
                            color: this.linesColor,
                            opacity: this.lineOpacity,
                            width: this.linesWidth
                        },
                        move: {
                            enable: true,
                            speed: this.moveSpeed,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        detect_on: 'canvas',
                        events: {
                            onhover: {
                                enable: this.hoverEffect,
                                mode: this.hoverMode
                            },
                            onclick: {
                                enable: this.clickEffect,
                                mode: this.clickMode
                            },
                            onresize: {

                                enable: true,
                                density_auto: true,
                                density_area: 400

                            }
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 1
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3
                            },
                            repulse: {
                                distance: 100,
                                duration: 0.4
                            },
                            push: {
                                particles_nb: 4
                            },
                            remove: {
                                particles_nb: 2
                            }
                        }
                    },
                    retina_detect: true
                }
            }
        },
        beforeCreate: function () {
            // Load particles JS only when DOM is full loaded
            window.onload = () => {
                this.enableParticles = true
            }
        }
    }

</script>

<style scoped lang='scss'>

    // ParticlesComponent
    //
    //
    //
    // Styleguide components.vue

    .particles-component {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        /* stylelint-disable */
        #particles-js {
            height: 100%;
        }
        /* stylelint-enable */
    }

</style>
