<template>
    <nav class="header-navbar">
        <a href="https://www.lahautesociete.com" target="_blank">
            <img src="@/static/images/logo-inline-white.svg" alt="La Haute Société" height="90">
        </a>

        <!-- Use a button element for accessibility -->
        <button
            ref="mobileMenuButton"
            class="header-navbar__hamburger hamburger hamburger--slider-r"
            type="button"
            name="navigation"
        >
            <span class="hamburger-box">
                <span class="hamburger-inner"/>
            </span>
        </button>
        <ul ref="menu" class="header-navbar__list">
            <li v-if="siteUrl">
                <a :href="siteUrl" class="header-navbar__item">Craft plugins</a>
            </li>
            <li v-if="contactUrl">
                <a :href="contactUrl" class="header-navbar__item">Contact</a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { TweenLite, CSSPlugin } from 'gsap/all'
    // eslint-disable-next-line
const plugins = [CSSPlugin];

    export default {
        name: 'NavbarComponent',
        props: {
            siteUrl: { type: String, default: null },
            contactUrl: { type: String, default: null }
        },
        data () {
            return {
                isMobileMenuOpen: false
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init () {
                this.addEventListeners()
            },

            addEventListeners () {
                this.$refs.mobileMenuButton.addEventListener('click', this.onMobileMenuButtonClick.bind(this))
            },

            onMobileMenuButtonClick () {
                if (!this.isMobileMenuOpen) {
                    this.$refs.mobileMenuButton.classList.add('is-active')
                    this.openNavbar()
                } else {
                    this.closeNavbar()
                    this.$refs.mobileMenuButton.classList.remove('is-active')
                }
                this.isMobileMenuOpen = !this.isMobileMenuOpen
            },

            openNavbar () {
                TweenLite.set(this.$refs.menu, { height: 'auto' })
                TweenLite.from(this.$refs.menu, 0.2, { height: 0 })
            },

            closeNavbar () {
                TweenLite.to(this.$refs.menu, 0.2, { height: 0 })
            }
        }
    }
</script>
